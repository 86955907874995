import React, { useState } from "react"

const FAQ = () => {
  const [activeFaq, setActiveFaq] = useState(1)
  return (
    <div>
      <section className="tf-faq tf-section tf-faq-ss faq">
        <div className="tf-container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div
                className="title-ss wow fadeInUp"
                style={{ visibility: "visible", animationName: "fadeInUp" }}
              >
                <h3>FAQ</h3>
              </div>
            </div>

            <div className="col-md-8">
              <div
                className="tf-flat-accordion2 wow fadeInUp"
                style={{ visibility: "visible", animationName: "fadeInUp" }}
              >
                <div className="flat-toggle2 active">
                  <div className="d-flex justify-content-between align-items-center">
                    <h6
                      className={`toggle-title ${
                        activeFaq === 1 ? "active" : ""
                      }`}
                    >
                      How Do I Use Twitter Tools?
                    </h6>
                    {activeFaq === 1 && (
                      <i
                        class="fa-solid fa-minus"
                        onClick={() => setActiveFaq(0)}
                      ></i>
                    )}
                    {activeFaq != 1 && (
                      <i
                        class="fa-solid fa-plus accordion-plus-icon"
                        onClick={() => setActiveFaq(1)}
                      ></i>
                    )}
                  </div>
                  <div
                    className={`toggle-content ${
                      activeFaq === 1 ? "d-block" : "d-none"
                    }`}
                  >
                    <p>
                     TwitterTools are a suite of tools designed for crypto users centered around Telegram and soon will be expanded to other platforms. 
                     The suite of tools can be used via the Twitter Tools Telegram Group and later via the Twitter Tools website.
                    </p>
                  </div>
                </div>
                <div className="flat-toggle2">
                  <div className="d-flex justify-content-between align-items-center">
                    <h6
                      className={`toggle-title ${
                        activeFaq === 2 ? "active" : ""
                      }`}
                    >
                      Are Your Tools Free?
                    </h6>
                    {activeFaq === 2 && (
                      <i
                        class="fa-solid fa-minus"
                        onClick={() => setActiveFaq(0)}
                      ></i>
                    )}
                    {activeFaq != 2 && (
                      <i
                        class="fa-solid fa-plus accordion-plus-icon"
                        onClick={() => setActiveFaq(2)}
                      ></i>
                    )}
                  </div>
                  <div
                    className={`toggle-content ${
                      activeFaq === 2 ? "d-block" : "d-none"
                    }`}
                  >
                    <p>
                     Twitter Tools Shout, Feed and Tweet Launch are free for all users. Twitter Tools AI Agent will be a paid service. As our user base increases we will add advertisements to all of our free tools.
                     This will generate revenue which, a portion of which, will be distributed to all holders on a weekly basis.
                    </p>
                  </div>
                </div>
                <div className="flat-toggle2">
                  <div className="d-flex justify-content-between align-items-center">
                    <h6
                      className={`toggle-title ${
                        activeFaq === 3 ? "active" : ""
                      }`}
                    >
                      Will The Contract be Renounced and LP Locked?
                    </h6>
                    {activeFaq === 3 && (
                      <i
                        class="fa-solid fa-minus"
                        onClick={() => setActiveFaq(0)}
                      ></i>
                    )}
                    {activeFaq != 3 && (
                      <i
                        class="fa-solid fa-plus accordion-plus-icon"
                        onClick={() => setActiveFaq(3)}
                      ></i>
                    )}
                  </div>
                  <div
                    className={`toggle-content ${
                      activeFaq === 3 ? "d-block" : "d-none"
                    }`}
                  >
                    <p>
                      Yes, The contract has been renounced. The LP has been locked via Unicrypt. You can view the links here:
                    </p>
                    <h5 className="name">
                      <a
                        className="text-decoration-none contract-link"
                        href="https://etherscan.io/tx/0x3397b63b6bf1ac1443bad52a669a1b5dda264cf367ecefdaad55c9f2d827bf91">LP Locked</a>
                        <br></br>
                      <a
                        className="text-decoration-none contract-link"
                        href="https://etherscan.io/tx/0x23801a2d3a26adda1697198fad6c773521c78f5af2ea587b46500575ecbbca01">Renounced</a>
                       
                      
                    </h5>
                    <p></p>
                  </div>
                </div>
                <div className="flat-toggle2">
                  <div className="d-flex justify-content-between align-items-center">
                    <h6
                      className={`toggle-title ${
                        activeFaq === 4 ? "active" : ""
                      }`}
                    >
                      Why Are You Launching a Token on Ethereum?
                    </h6>
                    {activeFaq === 4 && (
                      <i
                        class="fa-solid fa-minus"
                        onClick={() => setActiveFaq(0)}
                      ></i>
                    )}
                    {activeFaq != 4 && (
                      <i
                        class="fa-solid fa-plus accordion-plus-icon"
                        onClick={() => setActiveFaq(4)}
                      ></i>
                    )}
                  </div>
                  <div
                    className={`toggle-content ${
                      activeFaq === 4 ? "d-block" : "d-none"
                    }`}
                  >
                    <p>$TTOOLS is the governing token of Twitter Tools. The taxes generated from the token will help us develop more tools in our road map and it also provides a mechanism for distributing a revenue share to all holders.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default FAQ
