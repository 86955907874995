import React, { useEffect, useState } from "react"
import LogoImageSlider from "../assets/images/logoimageslider.png"
import LogoImageSlider1 from "../assets/images/logoimageslider2.png"
import LogoImageSlider2 from "../assets/images/logoimageslider3.png"
import LogoVideoSlider from "../assets/images/logovideoslider.mp4"

const Swiper = () => {
  const [visible, setVisible] = useState(true)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisible(false)
    }, 2000)

    return () => clearTimeout(timeout)
  }, [])

  return (
    <div className="px-4">
      <swiper-container>
        <swiper-slide>
          <div
            class="swiper-slide swiper-slide-duplicate swiper-slide-active swiper-slide-duplicate-next"
            data-swiper-slide-index="0"
            role="group"
            aria-label="1 / 1"
          >
            <div class="slider-item">
              <div class="tf-slider-item style-3">
                <div class="content-inner">
                  <h1 class="heading mb0">
                    WELCOME TO&nbsp;
                    <span class="animationtext clip">
                      <span class="cd-words-wrapper">
                        <span class="item-text is-hidden"> TWITTER TOOLS</span>
                        <span class="item-text is-hidden"> TWITTER TOOLS</span>
                        <span class="item-text is-visible"> TWITTER TOOLS</span>
                      </span>
                    </span>
                  </h1>
                  <h1 class="heading">A SUITE OF TOOLS FOR CRYPTO USERS</h1>
                  <p class="sub-heading">
                  Welcome to Twitter Tools, the comprehensive one-stop platform tailored to streamline and amplify your Twitter-based crypto trading and development pursuits. Amidst the crypto buzz on Twitter, we're excited to introduce an exclusive project that offers advanced utility tools for crypto traders, developers, and enthusiasts alike.
                  <br></br> <br></br>
                  Our primary mission is to accelerate the ease of of buying and promoting cryptocurrencies on a large scale by eliminating technical obstacles and forging a more user-friendly environment for everyone.
                  <br></br> <br></br>
                   Official CA: 0xefe243f87feb8acff400be80b3a61c0c8178d014
                   </p>
                  <div class="btn-slider ">
                    <a href="https://app.uniswap.org/#/swap?outputCurrency=0xefe243f87feb8acff400be80b3a61c0c8178d014" class="tf-button" target="_blank">
                      BUY TOKEN
                    </a>
                    <a href="https://t.me/Twitter_TooIs_Bot" class="tf-button style-2" target="_blank">
                      V2 BOT
                    </a>
                  </div>
                  <footer class="footer style-5">
                    <ul
                      class="social-item wow fadeInRight"
                      data-wow-duration="1.5s"
                      //   style="visibility: visible; animation-duration: 1.5s;;"
                    >
                      <li>
                        <a
                          href="https://twitter.com/TToolsAi"
                          target="_blank"
                        >
                          <i class="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://t.me/Twitter_Tools_ETH" target="_blank">
                          <i class="fab fa-telegram-plane"></i>
                        </a>
                      </li>


                    </ul>
                  </footer>
                </div>
                <div class="image">
                  <img src={LogoImageSlider} alt="Image" class="ani5" />
                  <img src={LogoImageSlider1} alt="Image" class="ani4 img-1" />
                  <img src={LogoImageSlider2} alt="Image" class="ani3 img-2" />
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div
            class="swiper-slide swiper-slide-duplicate swiper-slide-active swiper-slide-duplicate-next"
            data-swiper-slide-index="0"
            role="group"
            aria-label="1 / 1"
          >
            <div class="slider-item">
              <div class="tf-slider-item style-3">
                <div class="content-inner">
                  <h1 class="heading mb0">
                    WELCOME TO&nbsp;
                    <span class="animationtext clip">
                      <span class="cd-words-wrapper">
                        <span class="item-text is-hidden">TWITTER TOOLS</span>
                        <span class="item-text is-hidden">TWITTER TOOLS</span>
                        <span class="item-text is-visible">TWITTER TOOLS</span>
                      </span>
                    </span>
                  </h1>
                  <h1 class="heading">A SUITE OF TOOLS FOR CRYPTO USERS</h1>
                  <p class="sub-heading">
                  Welcome to Twitter Tools, the comprehensive one-stop platform tailored to streamline and amplify your Twitter-based crypto trading and development pursuits. Amidst the crypto buzz on Twitter, we're excited to introduce an exclusive project that offers advanced utility tools for crypto traders, developers, and enthusiasts alike.
                  <br></br> <br></br>
                  Our primary mission is to accelerate the ease of of buying and promoting cryptocurrencies on a large scale by eliminating technical obstacles and forging a more user-friendly environment for everyone.
                  <br></br> <br></br>
                   Official CA: 0xefe243f87feb8acff400be80b3a61c0c8178d014
                   </p>
                  <div class="btn-slider ">
                    <a href="https://app.uniswap.org/#/swap?outputCurrency=0xefe243f87feb8acff400be80b3a61c0c8178d014" class="tf-button" target="_blank">
                      BUY TOKEN
                    </a>
                    <a href="https://t.me/Twitter_TooIs_Bot" class="tf-button style-2" target="_blank">
                     V2 BOT
                    </a>
                  </div>
                  <footer class="footer style-5">
                    <ul
                      class="social-item wow fadeInRight"
                      data-wow-duration="1.5s"
                      //   style="visibility: visible; animation-duration: 1.5s;;"
                    >
                      <li>
                        <a
                          href="https://twitter.com/TToolsAi"
                          target="_blank"
                        >
                          <i class="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://t.me/Twitter_Tools_ETH" target="_blank">
                          <i class="fab fa-telegram-plane"></i>
                        </a>
                      </li>


                    </ul>
                  </footer>
                </div>
                <div class="image">
                  <img src={LogoImageSlider} alt="Image" class="ani5" />
                  <img src={LogoImageSlider1} alt="Image" class="ani4 img-1" />
                  <img src={LogoImageSlider2} alt="Image" class="ani3 img-2" />
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div
            class="swiper-slide swiper-slide-duplicate swiper-slide-active swiper-slide-duplicate-next"
            data-swiper-slide-index="0"
            role="group"
            aria-label="1 / 1"
          >
            <div class="slider-item">
              <div class="tf-slider-item style-3">
                <div class="content-inner">
                  <h1 class="heading mb0">
                    WELCOME TO&nbsp;
                    <span class="animationtext clip">
                      <span class="cd-words-wrapper">
                        <span class="item-text is-hidden">TWITTER TOOLS</span>
                        <span class="item-text is-hidden">TWITTER TOOLS</span>
                        <span class="item-text is-visible">TWITTER TOOLS</span>
                      </span>
                    </span>
                  </h1>
                  <h1 class="heading">A SUITE OF TOOLS FOR CRYPTO USERS</h1>
                  <p class="sub-heading">
                  Welcome to Twitter Tools, the comprehensive one-stop platform tailored to streamline and amplify your Twitter-based crypto trading and development pursuits. Amidst the crypto buzz on Twitter, we're excited to introduce an exclusive project that offers advanced utility tools for crypto traders, developers, and enthusiasts alike.
                  <br></br> <br></br>
                  Our primary mission is to accelerate the ease of of buying and promoting cryptocurrencies on a large scale by eliminating technical obstacles and forging a more user-friendly environment for everyone.
                  <br></br> <br></br>
                   Official CA: 0xefe243f87feb8acff400be80b3a61c0c8178d014
                   </p>
                  <div class="btn-slider ">
                    <a href="https://app.uniswap.org/#/swap?outputCurrency=0xefe243f87feb8acff400be80b3a61c0c8178d014" class="tf-button" target="_blank">
                      BUY TOKEN
                    </a>
                    <a href="https://t.me/Twitter_TooIs_Bot" class="tf-button style-2" target="_blank">
                      V2 BOT
                    </a>
                  </div>
                  <footer class="footer style-5">
                    <ul
                      class="social-item wow fadeInRight"
                      data-wow-duration="1.5s"
                      //   style="visibility: visible; animation-duration: 1.5s;;"
                    >
                      <li>
                        <a
                          href="https://twitter.com/TToolsAi"
                          target="_blank"
                        >
                          <i class="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://t.me/Twitter_Tools_ETH" target="_blank">
                          <i class="fab fa-telegram-plane"></i>
                        </a>
                      </li>

                      
                    </ul>
                  </footer>
                </div>
                <div class="image">
                  <img src={LogoImageSlider} alt="Image" class="ani5" />
                  <img src={LogoImageSlider1} alt="Image" class="ani4 img-1" />
                  <img src={LogoImageSlider2} alt="Image" class="ani3 img-2" />
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper-container>
    </div>
  )
}

export default Swiper
