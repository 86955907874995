import React from "react"
import logobottom from "../assets/images/logobottom.png"
import feed from "../assets/images/feed.jpg"
import assistant from "../assets/images/assistant.jpg"
import competition from "../assets/images/competition.jpg"
import comp from "../assets/images/comp.mp4"
import tweet from "../assets/images/tweetcomp.jpg"

const Footer = () => {
  return (
    <div>
      <footer class="footer2 style-2 footer-ss footer">
        <div class="footer-inner">
          <div class="tf-container">
            <div class="row">
              <div class="col-md-12">
                <h2 class="title">JOIN OUR COMMUNITY</h2>
                <p class="content">
                 Use Twitter Tools to become part of a growing community{" "}
                </p>
                <div class="col-md-12">
                  <h2 class="title">Contact Us</h2>
                  {/* <!--  <p class="content">https://t.me/Twitter_Tools_ETH</p> --> */}
                  <div class="group-btn">
                    <a
                      href="https://twitter.com/TToolsAi"
                      target="_blank"
                      class="tf-button discord"
                    >
                      <i class="fab fa-twitter"></i>
                      <span>TWITTER</span>
                    </a>
                    <a
                      href="https://t.me/Twitter_Tools_ETH"
                      target="_blank"
                      class="tf-button"
                    >
                      <i class="fab fa-telegram"></i>
                      <span>TELEGRAM</span>
                    </a>
                  </div>
                  <ul class="social-item ">
                    <li>
                      <a
                        href="https://twitter.com/TToolsAi"
                        target="_blank"
                      >
                        <i class="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://t.me/Twitter_Tools_ETH" target="_blank">
                        <i class="fab fa-telegram-plane"></i>
                      </a>
                    </li>
                   {/* <!--  <li>
                      <a
                        href="https://medium.com/"
                        target="_blank"
                      >
                        <i class="fab fa-medium"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.reddit.com"
                        target="_blank"
                      >
                        <i class="fab fa-reddit"></i>
                      </a>
                    </li> --> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="bottom-inner mt-50">
            <div class="tf-container">
              <div class="row">
                <div class="col-md-12">
                  <div class="bottom">
                    <div class="content-left">
                      <img src={logobottom} alt="Image" />
                      <p class="copy-right">
                        Twitter Tools 2023 - ALL rights reserved
                      </p>
                   <img src={feed} alt="Image" class="assistant" />
                     <br></br>
                 <img src={assistant} alt="Image" class="assistant" />
                       <br></br>
                    <img src={competition} alt="Image" class="assistant" />
                     <br></br>
                      <img src={tweet} alt="Image" class="assistant" />
                     <br></br>
                    <video src={comp} alt="Image" class="assistant" />
                 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer
