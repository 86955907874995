import React from "react"
import tokenomicsimage from "../assets/images/tokenomicsimage.png"

const ItemDetail = () => {
  return (
    <div>
      <section class="tf-item-detail item-detail" id="token">
        <div class="tf-container">
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <div class="tf-item-detail-image">
                <img src={tokenomicsimage} alt="Image" class="ani5" />
              </div>
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="tf-item-detail-inner">
                <h2 class="title">TOKENOMICS</h2>
                <p class="des">
                We are a 3% low taxed token. Why?  Continued development of the Twitter Tools ecosystem is funded by this. We also use a portion of the taxes for marketing and building awareness.
                </p>
                <div class="infor-item-wrap">
                  <div class="infor-item-box">
                    <div class="category">3%</div>
                    <h4 class="name">BUY TAXES</h4>
                  </div>
                  <div class="infor-item-box">
                    <div class="category">3%</div>
                    <h4 class="name">SELL TAXES</h4>
                  </div>
                  <div class="infor-item-box">
                    <div class="category">100%</div>
                    <h4 class="name">SAFU</h4>
                  </div>
                </div>
                <div class="group-btn">
                  <a
                    href="https://app.uniswap.org/#/swap?outputCurrency=0xefe243f87feb8acff400be80b3a61c0c8178d014"
                    target="_blank"
                    class="tf-button opensea"
                  >
                    {/* <i class="fa-regular fa-bag-shopping"></i> */}
                    BUY NOW
                  </a>
                  <div class="group-btn">
                    <button
                      class="tf-button opensea"
                      onclick="myFunction()"
                      value="0xefe243f87feb8acff400be80b3a61c0c8178d014"
                    >
                      COPY CONTRACT
                    </button>
                    <input
                      class="textarea"
                      type="text"
                      value="#"
                      id="myInput"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ItemDetail
