import React from "react"
import swap from "../assets/images/swap.png"

const About = () => {
  return (
    <div>
      <section class="tf-section tf-about style-2 about" id="swap">
        <div class="tf-container">
          <div class="row">
            <div class="col-xl-6 col-lg-5 col-md-12">
              <div
                class="tf-heading style-2 wow fadeInUp"
                style={{ visibility: "visible", animationName: "fadeInUp" }}
              >
                <h2 class="heading">TWITTER TOOLS</h2>
                <p class="about-component-sub-heading sub-heading">
               Twitter Tools is our versatile Telegram bot designed to enhance your Crypto Twitter experience. From generated Tweets, providing real-time notifications for mentions, likes, and retweets, to discovering crypto launches related to the most-hyped tweets, Twitter Tools streamlines your Crypto Twitter management. But that's not all. 
               <br></br> <br></br>
               We're developing Twitter Tools to becoming an advanced AI agent—a Twitter bot capable of promoting projects on your behalf for a fee. By leveraging artificial intelligence and natural language processing, this AI agent will engage with potential users, market your projects, and maximize your reach. 
              <br></br> <br></br>
              Stay tuned as TwitterTools evolves into the ultimate assistant for Crypto Twitter marketing and project promotion.
                </p>
              </div>
              <div
                class="tf-heading style-2 wow fadeInUp"
                style={{ visibility: "visible", animationName: "fadeInUp" }}
              >
                {/* <!-- <h2 class="heading">Features</h2>
                                <br>
                                <p class="heading">+ Secure</p>
                                <p class="sub-heading"></p>
                                <br>
                                <p class="heading">+ Fast</p>
                                <p class="sub-heading"></p>
                                <br>
                                <p class="heading">+ Low Swap fees</p>
                                <p class="sub-heading"></p>
                                <br>
                                <p class="heading">+ Decentralized</p>
                                <p class="sub-heading"></p> --> */}
              </div>
            </div>
            <div class="col-lg-6 col-md-12">
              <img src={swap} class="ani4" />
            </div>
            <h2 class="heading col-12 col-xxl-2" id="features-heading">
              Tools
            </h2>
            <div class="tf-container2 col-12 col-xxl-10">
              <div class="row">
                <div class="col-md-12"></div>
                <div class="col-md-6 col-sm-6">
                  <div
                    class="tf-step style2  wow fadeInUp"
                    style={{ visibility: "visible", animationName: "fadeInUp" }}
                  >
                    <div class="step-title">
                      <div class="sub-number">01</div>
                      <h3>SHOUT</h3>
                    </div>
                    <p>
                   Twitter Tools Shout is an easy way to get the word out about your favorite crypto project. Just add in the token name, add hashtags, tell the bot how you feel about the project and then our AI bot will produce a tweet for you in real time.
                    </p>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div
                    class="tf-step style2 wow fadeInUp"
                    style={{ visibility: "visible", animationName: "fadeInUp" }}
                  >
                    <div class="step-title">
                      <div class="sub-number">02</div>
                      <h3>FEED</h3>
                    </div>
                    <p>
                   Twitter Tools Feed allows you to find all of the latest trends in the Crypto Twitter space. Get the latest #hashtags, trends and more. Never miss out on a hyped launch again.
                    </p>
                  </div>
                </div>
               
                <div class="col-md-6 col-sm-6">
                  <div
                    class="tf-step style2 wow fadeInUp"
                    style={{
                      visibility: "visible",
                      animationName: "fadeInUp",
                      paddingBottom: "4.2rem",
                    }}
                  >
                      <div class="step-title">
                      <div class="sub-number">03</div>
                      <h3>TWEET LAUNCH</h3>
                    </div>
                    <p>
                    Twitter Tools Launch makes sure that you are able to buy into the most hyped launches based on Tweets right from the start. If Elon sends out a tweet, Twitter Tools Launch will send you all of the token contracts deployed that are related to that tweet.
                    </p>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div
                    class="tf-step style2 wow fadeInUp"
                    style={{
                      visibility: "visible",
                      animationName: "fadeInUp",
                      paddingBottom: "4.2rem",
                    }}
                  >
                    <div class="step-title">
                      <div class="sub-number">04</div>
                      <h3>AI TWEET AGENT</h3>
                    </div>
                    <p>
                    The Twitter Tools AI Tweet Agent is an all encompassing AI bot that assists project owners and developers in promoting their latest token. From auto generated tweets for raiding to auto generated comments for shilling, the AI Tweet Agent will have you covered.
                    </p>
                    <br />
                  </div>
                </div>
                <div
                  class="col-md-12 wow fadeInUp"
                  style={{ visibility: "visible", animationName: "fadeInUp" }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default About
